<template>
  <b-modal
    id="dockLockPayModal"
    ref="dockLockPayModal"
    class="modal-xl"
    title="Payment"
    title-tag="h3"
    centered
    size="xl"
    @hide="unsetOnClose"
  >
    <b-container
      class="dock-lock-pay-modal"
      fluid
    >
      <payment-method-options />
      <b-form>
        <h5>
          Invoices
        </h5>
        <b-list-group>
          <b-list-group-item class="d-flex justify-content-between align-items-center">
            <b-button variant="link" aria-label="company link">
              <p class="mb-1">
                {{ companyName }}
              </p>
            </b-button>
            <strong>${{ price }}</strong>
          </b-list-group-item>
          <b-table :items="items" :fields="invoiceFields" striped />
        </b-list-group>
        <br>
        <div class="text-right">
          <h5>
            <strong>Total:</strong>
            ${{ price }}
          </h5>
        </div>
      </b-form>
    </b-container>
    <template v-slot:modal-footer="{ }">
      <div v-if="paymentLoading" class="d-flex flex-row justify-content-center" style="width: 100%">
        <ct-centered-spinner />
      </div>
      <b-button
        v-else :disabled="!valid"
        class="submit-button"
        variant="primary"
        aria-label="submit payment button"
        @click="pay()"
      >
        Submit Payment
      </b-button>
    </template>
  </b-modal>
</template>

<script>
import CtCenteredSpinner from './shared/CtCenteredSpinner'
import PaymentMethodOptions from '@/components/Payments/PaymentMethodOptions'
import { paymentMethodsMixin } from '@/mixins/paymentMethodsMixin'

export default {
  name: 'DocLockPayModal',
  components: {
    CtCenteredSpinner,
    PaymentMethodOptions,
  },
  mixins: [paymentMethodsMixin],
  props: {
    bus: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      companyName: '',
      invoiceFields: ['description', 'quantity', 'price'],
      paymentLoading: false,
      price: 0,
    }
  },
  computed: {
    items() {
        return [{ price: '$'+this.price, description: this.description, quantity: 1 }]
    },
    valid() {
      return this.selectedPaymentMethod && this.agreementAccepted
    },
  },
  async mounted() {
    this.bus.$on('showDockLockPayModal', this.open)
  },
  methods: {
    open(params) {
      this.$refs.dockLockPayModal.show()
      this.companyName = params['companyName']
      this.price       = params['price']
      this.description = params['description']
      this.documentId  = params['documentId']
      this.docLockType = params['docLockType']

      this.setCurrentTotal(this.price)
    },
    async pay() {
      this.paymentLoading = true
      let response
      const documentId = this.documentId
      const payableId = this.selectedPaymentMethod.id

      if (this.docLockType === 'legal_request') {
        response = await this.$store.dispatch('invoices/payLegalRequestUnlockInvoice', {
          documentId,
          payableId,
        })
      } else {
        response = await this.$store.dispatch('invoices/generateAndPayDocumentUnlockInvoice', {
          documentId,
          payableId,
        })
      }

      if (response && response.success) {
        this.$emit('completed')
      } else {
        this.$bvToast.toast(response.data.error.message, {
          title: 'Error',
          variant: 'danger',
          solid: true,
        })
      }
      this.paymentLoading = false
    },
    unsetOnClose() {
      this.resetPaymentMethod()
    },
  },
}
</script>

<style scoped lang="scss">

.submit-button {
  border-radius: 4px !important;
  padding: 8px 16px 8px 10px !important;
}

.dock-lock-pay-modal {
  // custom striped styling
  ::v-deep(.table-striped) {

    tbody tr:nth-of-type(odd) {
      background: #FFF;
    }

    tbody tr:nth-of-type(even) {
      background: #F5F6F9;
    }
  }
}
</style>
