var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-modal",
    {
      ref: "docLockModal",
      attrs: {
        id: "docLockModal",
        title: "Document Unlock",
        size: "lg",
        "hide-footer": "",
        "hide-header": "",
      },
    },
    [
      !_vm.loading && _vm.reason === "Service Requiring Attention"
        ? _c("b-container", { staticClass: "pb-4" }, [
            _c("h2", [
              _vm._v(
                "A service requires your attention in " +
                  _vm._s(_vm.document.jurisdiction) +
                  "."
              ),
            ]),
            _c("div", [
              _c("p", [
                _vm._v(
                  "\n        Please fill out the required information for this service in order to unlock the document.\n      "
                ),
              ]),
            ]),
          ])
        : !_vm.loading && _vm.reason === "No Active Service"
        ? _c("b-container", { staticClass: "pb-4" }, [
            _c("h2", [
              _vm._v(
                "No Active Service in " + _vm._s(_vm.document.jurisdiction)
              ),
            ]),
            _c("div", [
              _c("p", [
                _vm._v(
                  "\n        You did not have active service in " +
                    _vm._s(_vm.document.jurisdiction) +
                    " when\n        this document was received.\n      "
                ),
              ]),
              _c("p", [
                _vm._v(
                  "You may pay $" +
                    _vm._s(_vm.lockData.price) +
                    " to view this document."
                ),
              ]),
            ]),
          ])
        : !_vm.loading && _vm.reason === "Overdue Unpaid Invoices Exist"
        ? _c("b-container", { staticClass: "pb-4" }, [
            _c("h2", [_vm._v("Invoice Payment Required")]),
            _c(
              "div",
              [
                _c("p", [
                  _vm._v(
                    "\n        The following invoices must be paid before you can view this document:\n      "
                  ),
                ]),
                _vm._l(_vm.invoices, function (invoice) {
                  return _c("p", { key: invoice.id }, [
                    _vm._v(
                      "\n        Invoice #: " +
                        _vm._s(invoice.invoice_number) +
                        " - $" +
                        _vm._s(invoice.total) +
                        "\n      "
                    ),
                  ])
                }),
              ],
              2
            ),
          ])
        : !_vm.loading && _vm.reason === "Over Upload Limit"
        ? _c("b-container", { staticClass: "pb-3" }, [
            _c("h2", [
              _vm._v(
                "Document upload limit exceeded in " +
                  _vm._s(_vm.document.jurisdiction)
              ),
            ]),
            _c("div", [
              _c("p", [
                _vm._v(
                  "\n        You've exceeded the document upload limit in " +
                    _vm._s(_vm.document.jurisdiction) +
                    ",\n        "
                ),
                _c("br"),
                _vm._v(
                  "\n        which is where this document was received.\n        "
                ),
                _c("br"),
                _vm._v(
                  "\n        At the time of receiving this document, you were allowed\n        "
                ),
                _c("br"),
                _vm._v(
                  "\n        " +
                    _vm._s(_vm.lockData.data.limit) +
                    " documents and received " +
                    _vm._s(_vm.lockData.data.usage) +
                    ".\n      "
                ),
              ]),
            ]),
          ])
        : !_vm.loading && _vm.reason === "Unlocked"
        ? _c("b-container", { staticClass: "pb-4" }, [
            _c("h2", [_vm._v("Unlocked")]),
            _c("div", [
              _c("p", [
                _vm._v(
                  "\n        " + _vm._s(_vm.lockData.description) + "\n      "
                ),
              ]),
            ]),
          ])
        : !_vm.loading && _vm.reason === "Law On Call Document Locked"
        ? _c("b-container", { staticClass: "pb-4" }, [
            _c("h2", [_vm._v("Download Completed Legal Request")]),
            _c("div", [
              _c("p", [
                _vm._v(
                  "\n        The following invoice must be paid before you can download this Completed Legal Request.\n      "
                ),
              ]),
            ]),
          ])
        : _vm._e(),
      !_vm.loading
        ? _c(
            "div",
            { staticStyle: { "text-align": "center" } },
            [
              _vm.reason === "Service Requiring Attention"
                ? _c(
                    "router-link",
                    { attrs: { to: "/services-requiring-attention" } },
                    [
                      _c(
                        "b-button",
                        {
                          staticClass: "centered mt-2",
                          attrs: {
                            variant: "primary",
                            "aria-label": "required information button",
                          },
                        },
                        [
                          _vm._v(
                            "\n        Fill out the required information for the service\n      "
                          ),
                        ]
                      ),
                    ],
                    1
                  )
                : _vm.canUpsellMailForwarding
                ? _c(
                    "div",
                    { staticClass: "button-container" },
                    [
                      _c(
                        "b-col",
                        [
                          _c(
                            "b-button",
                            {
                              attrs: {
                                variant: "outline-primary",
                                "aria-label": "unlock invoice button",
                              },
                              on: { click: _vm.payInvoices },
                            },
                            [
                              _vm._v(
                                "\n          Unlock This Document Only - $" +
                                  _vm._s(_vm.lockData.price) +
                                  "\n        "
                              ),
                            ]
                          ),
                          _c("div", { staticClass: "upgrade-header mt-3" }, [
                            _c("h3", [_vm._v("Upgrade Options Available")]),
                            _vm.currentRaLimit > 0
                              ? _c("span", [
                                  _vm._v(
                                    "Current Document Limit: " +
                                      _vm._s(_vm.currentRaLimit)
                                  ),
                                ])
                              : _vm._e(),
                          ]),
                          _c(
                            "div",
                            { staticClass: "keep-together" },
                            [
                              _c(
                                "b-button",
                                {
                                  staticClass: "mt-2 btn-width",
                                  attrs: {
                                    variant: "primary",
                                    "aria-label":
                                      "upgrade to unique suite button",
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.upsellMailForwarding(
                                        _vm.mailForwardingOption
                                      )
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n            Upgrade to Unique Suite - $" +
                                      _vm._s(_vm.mailForwardingOption.price) +
                                      _vm._s(_vm.duration) +
                                      "\n          "
                                  ),
                                ]
                              ),
                              _c(
                                "b-button",
                                {
                                  staticClass: "btn-icon",
                                  attrs: {
                                    id: "tooltip-target-1",
                                    size: "sm",
                                    variant: "outline",
                                    "aria-label": "tooltip button",
                                  },
                                },
                                [
                                  _c("b-icon", {
                                    attrs: { icon: "question-circle-fill" },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "b-tooltip",
                                {
                                  attrs: {
                                    target: "tooltip-target-1",
                                    triggers: "hover",
                                    placement: "top",
                                  },
                                },
                                [
                                  _c("ul", { staticClass: "m-0 mr-3" }, [
                                    _c("li", [_vm._v("Unique Suite Number")]),
                                    _c("li", [
                                      _vm._v(
                                        _vm._s(_vm.totalUploadLimitToolTipText)
                                      ),
                                    ]),
                                  ]),
                                ]
                              ),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            { staticClass: "keep-together" },
                            [
                              _c(
                                "b-button",
                                {
                                  staticClass: "mt-2 btn-width",
                                  attrs: {
                                    variant: "primary",
                                    "aria-label":
                                      "upgrade to virtual office button",
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.upsellMailForwarding(
                                        _vm.mailForwardingVirtualOffice
                                      )
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n            Upgrade to Virtual Office - $" +
                                      _vm._s(
                                        _vm.mailForwardingVirtualOffice.price
                                      ) +
                                      "/Month\n          "
                                  ),
                                ]
                              ),
                              _c(
                                "b-button",
                                {
                                  staticClass: "btn-icon",
                                  attrs: {
                                    id: "tooltip-target-2",
                                    size: "sm",
                                    variant: "outline",
                                    "aria-label": "tooltip button",
                                  },
                                },
                                [
                                  _c("b-icon", {
                                    attrs: { icon: "question-circle-fill" },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "b-tooltip",
                                {
                                  attrs: {
                                    target: "tooltip-target-2",
                                    triggers: "hover",
                                    placement: "top",
                                  },
                                },
                                [
                                  _c("ul", { staticClass: "m-0 mr-3" }, [
                                    _c("li", [_vm._v("Unique Suite Number")]),
                                    _c("li", [_vm._v("Office Lease")]),
                                    _c("li", [
                                      _vm._v("Unlimited Document Uploads"),
                                    ]),
                                    _c("li", [_vm._v("Virtual Phone")]),
                                  ]),
                                ]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm.reason !== "Unlocked"
                ? _c(
                    "b-button",
                    {
                      staticClass: "centered mt-2",
                      attrs: {
                        variant: "primary",
                        "aria-label": "view document button",
                      },
                      on: { click: _vm.payInvoices },
                    },
                    [
                      _vm._v(
                        "\n      View document for $" +
                          _vm._s(_vm.lockData.price) +
                          "\n    "
                      ),
                    ]
                  )
                : _vm._e(),
            ],
            1
          )
        : _c("ct-centered-spinner"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }